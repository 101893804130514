<template>
	<div>
		<a-modal :footer="null" :visible="showFP" @cancel="showFP = false">
			<a-carousel arrows class="carousel-modal">
				<template #prevArrow>
					<div class="custom-slick-arrow" style="left: 10px; z-index: 1">
						<svg style="width:20px;height:20px;color:black" viewBox="64 64 896 896" data-icon="arrow-left"
							fill="currentColor" aria-hidden="true" focusable="false" class="">
							<path
								d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 0 0 0 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z">
							</path>
						</svg>
					</div>
				</template>
				<template #nextArrow>
					<div class="custom-slick-arrow" style="right: 10px">
						<svg style="width:20px;height:20px;color:black" focusable="false" class=""
							data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"
							viewBox="64 64 896 896">
							<path
								d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z">
							</path>
						</svg>
					</div>
				</template>
				<img :src="elevation.image" v-for="(elevation, imgI) in elevationImages" alt="elevation"
					:key="elevation.image + imgI" />
			</a-carousel>
		</a-modal>
		<a-modal :footer="null" :visible="showPurchaserDocs" @cancel="showPurchaserDocs = false">
			<a-carousel arrows class="carousel-modal">
				<template #prevArrow>
					<div class="custom-slick-arrow" style="left: 10px; z-index: 1">
						<svg style="width:20px;height:20px;color:black" viewBox="64 64 896 896" data-icon="arrow-left"
							fill="currentColor" aria-hidden="true" focusable="false" class="">
							<path
								d="M872 474H286.9l350.2-304c5.6-4.9 2.2-14-5.2-14h-88.5c-3.9 0-7.6 1.4-10.5 3.9L155 487.8a31.96 31.96 0 0 0 0 48.3L535.1 866c1.5 1.3 3.3 2 5.2 2h91.5c7.4 0 10.8-9.2 5.2-14L286.9 550H872c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z">
							</path>
						</svg>
					</div>
				</template>
				<template #nextArrow>
					<div class="custom-slick-arrow" style="right: 10px">
						<svg style="width:20px;height:20px;color:black" focusable="false" class=""
							data-icon="arrow-right" width="1em" height="1em" fill="currentColor" aria-hidden="true"
							viewBox="64 64 896 896">
							<path
								d="M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91.5c1.9 0 3.8-.7 5.2-2L869 536.2a32.07 32.07 0 000-48.4z">
							</path>
						</svg>
					</div>
				</template>
				<img :src="img" v-for="(img, imgI) in purchaserImages" alt="Purchaser Docs" :key="img + imgI" />
			</a-carousel>
		</a-modal>

		<div class="contract-details-card general-summary">
			<div v-if="showAmendmentNote" class="mb-3 dF aC jSB">
				<a-alert show-icon type="info">
					<template #message>
						<div>
							<strong>Important:</strong> An amendment(s) has been made and finalized which may impact the terms of this Agreement. Please review the amendment(s) in the <span class="link" @click="$emit('changeTab', 'docs')"> documents section </span> for more information.
						</div>
					</template>
				</a-alert>
			</div>
			<div class="w-full dF jSB aC ant-descriptions-title">
				Offer Details
				<div>
					<small style="font-weight:300; background:white; border-radius:40px;"
						class="px-3 py-2 inline-block">
						<a-badge :status="transactionStatus.color" :text="transactionStatus.text" />
					</small>
					<small style="font-weight:300; background:white; border-radius:40px;"
						class="px-3 py-2 inline-block ml-3" v-if="transactionStatus.isComplete">
						<a-badge status="error"
							:text="transactionStatus.isConditional ? productStatus + ' Conditional' : productStatus" />
					</small>
					<small style="font-weight:300; background:white; border-radius:40px;"
						class="px-3 py-2 inline-block ml-3" v-else-if="transactionStatus.allPurchaserSigned">
						<a-badge color="purple" :text="productStatus + ' Conditional'" />
					</small>
				</div>

			</div>

			<a-card v-if="productType === 'lowrise'">
				<a-descriptions layout="horizontal">
					<div slot="title" class="w-full dF jSB aC">
						Summary
						<small style="font-weight:300; background:var(--off-white-dark); border-radius:40px;"
							class="px-3 py-2">Created on {{ formatDate(transaction.createdAt) }}</small>
					</div>
					<a-descriptions-item label="Offer Date">
						{{ formatDate(transaction.createdAt) }}
					</a-descriptions-item>
					<a-descriptions-item label="Product">
						Lot {{ product.lot && product.lot.name }}{{ product?.lot?.block ? ` (Block ${product.lot.block})` : '' }} ({{ unit && unit.unitGroup && unit.unitGroup.name }}
						{{ unit && unit.name }})
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Elevation Price">
						${{ formatNumber(pkgPrice || 0) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Premium Price">
						${{ formatNumber(lotPremiums || 0) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Suggested MSRP">
						${{ formatNumber(msrp || 0) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Purchase Price">
						${{ formatNumber(transaction.offer.purchasePrice) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="purchaseDate" label="Purchase Date">
						{{ formatDate(purchaseDate) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isLease" label="Monthly Lease Rate">
						${{ formatNumber(transaction.offer.purchasePrice) }}
					</a-descriptions-item>

					<a-descriptions-item v-if="isPurchase" label="Irrevocable Date">
						{{ transaction.offer.irrevocableDate === 'N/A' ? 'N/A' :
							formatDate(transaction.offer.irrevocableDate) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Closing Date">
                        {{ moment(transaction.offer.closingDate).isValid() ? formatDate(transaction.offer.closingDate) : 'N/A' }}
						({{ (transaction.offer.closingType && transaction.offer.closingType[0].toUpperCase()) + transaction.offer.closingType.substr(1) }})
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Offer Type">
						<span>{{ purchaseOfferLabel }}&nbsp;<strong v-if="conditionalHTML"
								v-html="conditionalHTML"></strong></span>
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase && graceDateLabel && graceEndDate" :label="graceDateLabel">
						<span>{{ graceEndDate }}</span>
					</a-descriptions-item>
				</a-descriptions>
			</a-card>

			<a-card v-else-if="productType === 'highrise'">
				<a-descriptions layout="horizontal">
					<div slot="title" class="w-full dF jSB aC">
						Summary
						<small style="font-weight:300; background:var(--off-white-dark); border-radius:40px;"
							class="px-3 py-2">Created on {{ formatDate(transaction.createdAt) }}</small>
					</div>
					<a-descriptions-item label="Offer Date">
						{{ formatDate(transaction.createdAt) }}
					</a-descriptions-item>
					<a-descriptions-item label="Unit">
						{{ product.floor && product.floor.name }} - Unit #{{ product.unit && product.unit.unitNumber }}
						({{ product.unit && product.unit.name }})
					</a-descriptions-item>
                    <a-descriptions-item label="Legal Level" v-if="product.unit.legalLevel">
    					{{ product.unit.legalLevel }}
    				</a-descriptions-item>
					<a-descriptions-item label="Add Ons" v-if="product?.unit?.package?.other?.addons">
						<span>
							Parking - {{ product.unit.package.other.addons.parking &&
								product.unit.package.other.addons.parking.length || 0 }}, Lockers -
							{{ product.unit.package.other.addons.lockers &&
								product.unit.package.other.addons.lockers.length || 0
							}}, Bike Racks - {{ product.unit.package.other.addons.bikeRacks &&
								product.unit.package.other.addons.bikeRacks.length || 0 }}
						</span>
					</a-descriptions-item>
                    <a-descriptions-item label="Non Inventory Add Ons" v-if="product?.unit?.package?.other?.nonInventoryAddOns">
						<span>
							Parking - {{ product.unit.package.other.nonInventoryAddOns.parking &&
								product.unit.package.other.nonInventoryAddOns.parking.length || 0 }}, Lockers -
							{{ product.unit.package.other.nonInventoryAddOns.lockers &&
								product.unit.package.other.nonInventoryAddOns.lockers.length || 0 }}, Bike Racks -
							{{ product.unit.package.other.nonInventoryAddOns.bikeRacks &&
								product.unit.package.other.nonInventoryAddOns.bikeRacks.length || 0 }}
						</span>
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Unit Price">
						${{ formatNumber(pkgPrice || 0) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Add Ons Price">
						${{ formatNumber(transaction.offer.addOnsPrice || 0) }}
					</a-descriptions-item>
                    <a-descriptions-item v-if="isPurchase && transaction.offer.nonInventoryAddOnsPrice" label="Non Inventory Add Ons Price">
						${{ formatNumber(transaction.offer.nonInventoryAddOnsPrice || 0) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Premium Price">
						${{ formatNumber(unitPremiums || 0) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Suggested MSRP">
						${{ formatNumber(msrp || 0) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Purchase Price">
						${{ formatNumber(transaction.offer.purchasePrice) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="purchaseDate" label="Purchase Date">
						{{ formatDate(purchaseDate) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isLease" label="Monthly Lease Rate">
						${{ formatNumber(transaction.offer.totalRent) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="reservationAmount" label="Reservation Fee">
						${{ formatNumber(reservationAmount) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isLease" label="Tenancy Start Date">
						{{ formatDate(transaction.offer.tenancyStartDate) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isLease" label="Tenancy End Date">
						{{ formatDate(transaction.offer.tenancyEndDate) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isLease" label="Lease Deposit">
						${{ formatNumber(transaction.offer.deposit) }}
					</a-descriptions-item>

					<a-descriptions-item v-if="isPurchase" label="Irrevocable Date">
						{{ transaction.offer.irrevocableDate === 'N/A' ? 'N/A' :
							formatDate(transaction.offer.irrevocableDate) }}
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Closing Date">
						{{ moment(transaction.offer.closingDate).isValid() ? formatDate(transaction.offer.closingDate) : 'N/A' }}
                        ({{ (transaction.offer.closingType && transaction.offer.closingType[0].toUpperCase()) + transaction.offer.closingType.substr(1) }})
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase" label="Offer Type">
						<span>{{ purchaseOfferLabel }}&nbsp;<strong v-if="conditionalHTML"
								v-html="conditionalHTML"></strong></span>
					</a-descriptions-item>
					<a-descriptions-item v-if="isPurchase && graceDateLabel && graceEndDate" :label="graceDateLabel">
						<span>{{ graceEndDate }}</span>
					</a-descriptions-item>
				</a-descriptions>
			</a-card>
		</div>

		<div v-if="purchasers.length" class="contract-details-card mt-4">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Purchasers Details
			</div>

			<a-card>
				<div v-for="(purchaser, index) in purchasers" :key="index">
					<a-descriptions layout="horizontal" :column="{ xxl: 5, xl: 4, lg: 3, md: 3, sm: 2, xs: 1 }">
						<div slot="title" class="w-full dF jSB aC">
							Purchaser #{{ index + 1 }}
							<a-button @click="viewPurchaserDocs(purchaser)"
								v-if="(purchaser.fileFront && purchaser.fileBack) || purchaser.filePassport" ghost
								type="primary" size="small">View Document(s)</a-button>
						</div>
						<a-descriptions-item label="First Name">
							{{ purchaser.firstName }}
						</a-descriptions-item>
						<a-descriptions-item label="Last Name">
							{{ purchaser.lastName }}
						</a-descriptions-item>
						<a-descriptions-item label="Middle Name">
							{{ purchaser.middleName }}
						</a-descriptions-item>
						<a-descriptions-item label="Email">
							{{ purchaser.email }}
						</a-descriptions-item>
						<a-descriptions-item label="Phone Number">
							{{ purchaser.phone }}
						</a-descriptions-item>
						<a-descriptions-item label="Date of Birth">
							{{ purchaser.dob }}
						</a-descriptions-item>
						<a-descriptions-item label="Business Phone#">
							{{ purchaser.business }}
						</a-descriptions-item>
						<a-descriptions-item label="Cell Phone#">
							{{ purchaser.cell }}
						</a-descriptions-item>
						<a-descriptions-item label="Company">
							{{ purchaser.company }}
						</a-descriptions-item>
						<a-descriptions-item label="Occupation">
							{{ purchaser.occupation }}
						</a-descriptions-item>
						<a-descriptions-item label="Job Title">
							{{ purchaser.jobTitle }}
						</a-descriptions-item>
						<a-descriptions-item label="SSN/SIN #">
							{{ purchaser.ssnsin }}
						</a-descriptions-item>
						<a-descriptions-item label="Driver License #">
							{{ purchaser.idValue }}
						</a-descriptions-item>
						<a-descriptions-item label="License Issuing Jurisdiction">
							{{ purchaser.issuing }}
						</a-descriptions-item>
						<a-descriptions-item label="License Expiry">
							{{ purchaser.expiry }}
						</a-descriptions-item>
						<a-descriptions-item label="Passport">
							{{ purchaser.passportOtherValue }}
						</a-descriptions-item>
						<a-descriptions-item label="Passport Issuing Jurisdiction">
							{{ purchaser.passportOtherIssuing }}
						</a-descriptions-item>
						<a-descriptions-item label="Passport Issuing Jurisdiction - Country">
							{{ purchaser.passportOtherIssuingCountry }}
						</a-descriptions-item>
						<a-descriptions-item label="Passport Expiry">
							{{ purchaser.passportOtherExpiry }}
						</a-descriptions-item>
						<template v-if="purchaser.bcValue">
							<a-descriptions-item label="Birth Certificate">
								{{ purchaser.bcValue }}
							</a-descriptions-item>
							<a-descriptions-item label="Birth Certificate Issuing Jurisdiction">
								{{ purchaser.bcIssuing }}
							</a-descriptions-item>
							<a-descriptions-item label="Birth Certificate Issuing Jurisdiction - Country">
								{{ purchaser.bcIssuingCountry }}
							</a-descriptions-item>
							<a-descriptions-item label="Birth Place">
								{{ purchaser.birthPlace }}
							</a-descriptions-item>
							<a-descriptions-item label="Birth Certificate Date of Registration">
								{{ purchaser.registrationDate }}
							</a-descriptions-item>
							<a-descriptions-item label="Birth Certificate Date Issued">
								{{ purchaser.issueDate }}
							</a-descriptions-item>
						</template>
						<template v-if="purchaser.prValue">
							<a-descriptions-item label="PR">
								{{ purchaser.prValue }}
							</a-descriptions-item>
							<a-descriptions-item label="PR Issuing Jurisdiction">
								{{ purchaser.prIssuing }}
							</a-descriptions-item>
							<a-descriptions-item label="PR Issuing Jurisdiction - Country">
								{{ purchaser.prIssuingCountry }}
							</a-descriptions-item>
							<a-descriptions-item label="PR Expiry">
								{{ purchaser.prExpiry }}
							</a-descriptions-item>
						</template>
						<template v-if="purchaser.wpValue">
							<a-descriptions-item label="Work Permit">
								{{ purchaser.wpValue }}
							</a-descriptions-item>
							<a-descriptions-item label="Work Permit Issuing Jurisdiction">
								{{ purchaser.wpIssuing }}
							</a-descriptions-item>
							<a-descriptions-item label="Work Permit Issuing Jurisdiction - Country">
								{{ purchaser.wpIssuingCountry }}
							</a-descriptions-item>
							<a-descriptions-item label="Work Permit Expiry">
								{{ purchaser.wpExpiry }}
							</a-descriptions-item>
						</template>
						<template v-if="purchaser.otherValue">
							<a-descriptions-item :label="purchaser.otherName">
								{{ purchaser.otherValue }}
							</a-descriptions-item>
							<a-descriptions-item :label="`${purchaser.otherName} Issuing Jurisdiction`">
								{{ purchaser.otherIssuing }}
							</a-descriptions-item>
							<a-descriptions-item :label="`${purchaser.otherName} Issuing Jurisdiction - Country`">
								{{ purchaser.otherIssuingCountry }}
							</a-descriptions-item>
							<a-descriptions-item :label="`${purchaser.otherName} Expiry`">
								{{ purchaser.otherExpiry }}
							</a-descriptions-item>
						</template>
						<a-descriptions-item label="Address 1" :span="2">
							{{ purchaser.address1 }}
						</a-descriptions-item>
						<a-descriptions-item label="Address 2">
							{{ purchaser.address2 }}
						</a-descriptions-item>
						<a-descriptions-item label="City">
							{{ purchaser.city }}
						</a-descriptions-item>
						<a-descriptions-item label="State/Province">
							{{ purchaser.region }}
						</a-descriptions-item>
						<a-descriptions-item label="Zip/Postal Code">
							{{ purchaser.postal }}
						</a-descriptions-item>
						<a-descriptions-item label="Country">
							{{ purchaser.country }}
						</a-descriptions-item>
					</a-descriptions>
					<a-divider v-if="(purchasers.length - 1) !== index" class="mt-3"
						style="background: black; margin: 10px 0px 15px"></a-divider>
				</div>
			</a-card>
		</div>

		<div v-if="isPurchase" class="contract-details-card mt-4">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Deposit Structure
			</div>
			<a-descriptions layout="vertical" bordered class="bg-white">
				<a-descriptions-item :label="`Deposit #${depI + 1}`" v-for="(dep, depI) in deposits"
					:key="depI + 'deposit'">
					${{ $formatNumber(dep.amount) }} on {{ $formatDate(dep.date) }}
				</a-descriptions-item>
				<a-descriptions-item label="Balance Due on Closing">
					${{ $formatNumber(balance) }} {{ moment(transaction.offer.closingDate).isValid() ? 'on ' + $formatDate(transaction.offer.closingDate) : '' }}
				</a-descriptions-item>
			</a-descriptions>
		</div>


		<div class="contract-details-card mt-4">
			<div class="w-full dF jSB aC ant-descriptions-title">
				{{ productType === 'lowrise' ? 'Product Details' : 'Unit Details' }}
			</div>
			<a-descriptions layout="vertical" bordered class="bg-white" v-if="productType === 'lowrise'">
				<a-descriptions-item label="Lot#">
					Lot {{ product.lot && product.lot.name }} {{ product?.lot?.block ? ` (Block ${product.lot.block})` : '' }}
				</a-descriptions-item>
				<a-descriptions-item label="Lot Premiums">
					{{ lotPremiumsName }}
				</a-descriptions-item>
				<a-descriptions-item label="Premium Value">
					${{ formatNumber(transaction.offer.lotPremiums) }}
				</a-descriptions-item>
				<a-descriptions-item label="Model/Elevation">
					{{ unit && unit.unitGroup && unit.unitGroup.name }} {{ unit && unit.name }}
				</a-descriptions-item>

				<a-descriptions-item label="Base Price">
					${{ formatNumber(transaction.offer.basePrice) }}
				</a-descriptions-item>
				<a-descriptions-item>
					<div slot="label" class="dF jSB">
						<div>Chosen Floorplan Package</div>
						<div v-if="transaction.offer?.bricks?.length"> PreferredExterior Color Package</div>
						<a-button size="small" type="primary" ghost @click="showFP = true"
							v-if="elevationImages.length">View
							Elevation</a-button>
					</div>
					<div class="dF jSB">
						<div v-if="unit && unit.package">
							<strong>{{ unit.package.name }}</strong> <br />
							{{ unit.package.beds }} Beds, <br />
							{{ unit.package.baths }} Baths, <br />
							{{ unit.package.garages }} Garages, <br />
							{{ unit.package.sqft }} Sqft <br />
						</div>
						<div v-if="transaction.offer?.bricks?.length">
							<div v-for="(b, index) in transaction.offer.bricks" :key="index">
								<strong>{{ b.name }}{{ index == 0 ? ' (Preferred)' : '(Alternate)' }}</strong>
							</div>
						</div>
						<a-button size="small" type="primary" style="opacity: 0; pointer-events: none;">View
							Elevation</a-button>
					</div>
				</a-descriptions-item>
			</a-descriptions>

			<a-descriptions layout="vertical" bordered class="bg-white"
				v-if="productType === 'highrise' && product.unit" :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }">
				<a-descriptions-item label="Floor">
					{{ product.floor && product.floor.name }}
				</a-descriptions-item>
				<a-descriptions-item label="Unit#">
					{{ 'Unit #' + product.unit.unitNumber }}
				</a-descriptions-item>
				<a-descriptions-item label="Unit Code">
					{{ product.unit.unitCode }}
				</a-descriptions-item>
				<a-descriptions-item label="Marketing Name">
					{{ product.unit.name }}
				</a-descriptions-item>
                <a-descriptions-item label="Legal Level" v-if="product.unit.legalLevel">
					{{ product.unit.legalLevel }}
				</a-descriptions-item>
				<a-descriptions-item label="Unit Price" v-if="product.unit.packages && product.unit.packages.length">
					${{ formatNumber(product.unit.packages[0].price) }}
				</a-descriptions-item>
				<a-descriptions-item v-if="product.unit.packages && product.unit.packages.length" :span="2">
					<div slot="label" class="dF jSB">
						Unit Information
						<a-button size="small" type="primary" ghost @click="showFP = true"
							v-if="elevationImages.length">View
							Floorplan(s)</a-button>
					</div>

					{{ unit.packages[0].beds }} Beds, <br />
					{{ unit.packages[0].baths }} Baths, <br />
					{{ unit.packages[0].sqft }} Sqft, <br />
					{{ unit.packages[0].exposure }} View <br />
				</a-descriptions-item>
			</a-descriptions>
		</div>

		<div v-if="isPurchase && productType === 'highrise' && addOnDeposits.length" class="contract-details-card mt-4">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Add Ons Deposit Structure
			</div>

			<a-descriptions layout="vertical" bordered class="bg-white">
				<a-descriptions-item :label="`Deposit #${depI + 1}`" v-for="(dep, depI) in addOnDeposits"
					:key="depI + 'deposit'">
					${{ $formatNumber(dep.amount) }} on {{ $formatDate(dep.date) }}
				</a-descriptions-item>
				<a-descriptions-item label="Balance Due on Closing">
					${{ $formatNumber(addOnBalance) }} {{ moment(transaction.offer.closingDate).isValid() ? 'on ' + $formatDate(transaction.offer.closingDate) : '' }}
				</a-descriptions-item>
			</a-descriptions>
		</div>

		<div class="mt-4 mb-5"
			v-if="productType === 'highrise' && showInventoryAddOns">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Inventory Add Ons Details
			</div>

			<div style="background-color: white; padding: 10px" class="pb-5">
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="Parking Spot">
						<a-table :rowKey="(e) => e.id" :columns="parkingColumns"
							:data-source="product.unit.package.other.addons.parking || []" :pagination="false" />
					</a-tab-pane>
					<a-tab-pane key="2" tab="Locker">
						<a-table :rowKey="(e) => e.id" :columns="lockerColumns"
							:data-source="product.unit.package.other.addons.lockers || []" :pagination="false" />
					</a-tab-pane>
					<a-tab-pane key="3" tab="Bike Rack">
						<a-table :rowKey="(e) => e.id" :columns="bikeColumns"
							:data-source="product.unit.package.other.addons.bikeRacks || []" :pagination="false" />
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>

        <div class="mt-4 mb-5"
			v-if="productType === 'highrise' && showNonInventoryAddOns">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Non Inventory Add Ons Details
			</div>

			<div style="background-color: white; padding: 10px" class="pb-5">
				<a-tabs default-active-key="1">
					<a-tab-pane key="1" tab="Parking Spot">
						<a-table :rowKey="(e) => e.id" :columns="parkingColumns"
							:data-source="product.unit.package.other.nonInventoryAddOns.parking || []" :pagination="false" />
					</a-tab-pane>
					<a-tab-pane key="2" tab="Locker">
						<a-table :rowKey="(e) => e.id" :columns="lockerColumns"
							:data-source="product.unit.package.other.nonInventoryAddOns.lockers || []" :pagination="false" />
					</a-tab-pane>
					<a-tab-pane key="3" tab="Bike Rack">
						<a-table :rowKey="(e) => e.id" :columns="bikeColumns"
							:data-source="product.unit.package.other.nonInventoryAddOns.bikeRacks || []" :pagination="false" />
					</a-tab-pane>
				</a-tabs>
			</div>
		</div>

		<div v-if="commissionPayout" class="contract-details-card mt-4">
			<div class="w-full dF jSB aC ant-descriptions-title">
				Worksheet Commission Details
			</div>
			<a-card>
				<a-descriptions layout="horizontal" :column="5">
					<div slot="title" class="w-full dF aC">
						Summary
					</div>
					<a-descriptions-item label="Worksheet">
						<a :href="`https://worksheets.bildhive.${$tld}/edit/${commissionPayout.worksheet.id}`"
							target="_blank" style="color: var(--primary);">{{ commissionPayout.worksheet.readableId
							}}</a>
					</a-descriptions-item>
					<a-descriptions-item label="Agent Name">
						{{ commissionPayout.agent.firstName + " " + commissionPayout.agent.lastName }}
					</a-descriptions-item>
					<a-descriptions-item label="Agent Email">
						{{ commissionPayout.agent.email }}
					</a-descriptions-item>
					<a-descriptions-item label="Agent Phone">
						{{ commissionPayout.agent.phone }}
					</a-descriptions-item>
					<a-descriptions-item label="Agent Company">
						{{ commissionPayout.agent.company }}
					</a-descriptions-item>
				</a-descriptions>
				<a-divider></a-divider>
				<a-descriptions layout="horizontal" :column="4">
					<div slot="title" class="w-full dF aC">
						Commission Payouts
					</div>
				</a-descriptions>
				<a-table class="tableStyle" :columns="payoutColumns" :data-source="commissionPayouts"
					:row-key="(record) => record.id">
					<div slot="payoutType" slot-scope="payout" class="dF fC">
						<span v-if="payout.stage">
							Stage: {{ payout.stage }}
						</span>
						<span v-else>
							Date: {{ $formatDate(payout.date) }}
						</span>
					</div>
					<div slot="payoutAmount" slot-scope="payout" class="dF fC">
						${{ $formatNumber(payout.amount) }}
					</div>
					<div slot="payoutDate" slot-scope="payout" class="dF fC">
						{{ $formatDate(payout.date) }}
					</div>
				</a-table>
			</a-card>
		</div>
	</div>
</template>

<script>
import { formatDate, formatNumber, diffDays } from 'bh-mod'
import moment from 'moment'

export default {
	props: {
		showAmendmentNote: {
			type: Boolean,
			default: false
		},
	},
	data: () => ({
		showFP: false,
		showPurchaserDocs: false,
		commissionPayout: null,
		purchaserImages: [],
		parkingColumns: [
			{
				dataIndex: "pID",
				key: "pID",
				title: "Parking ID",
				sorter: (a, b) => a.pID < b.pID ? -1 : a.pID > b.pID ? 1 : 0,
			},
			{
				dataIndex: "name",
				key: "name",
				title: "Parking Spot #",
				sorter: (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
			},
			{
				dataIndex: "type",
				key: "type",
				title: "Parking Spot Type",
				sorter: (a, b) => a.type < b.type ? -1 : a.type > b.type ? 1 : 0,
			},
			{
				dataIndex: "price",
				key: "price",
				title: "Parking Spot Price",
				sorter: (a, b) => a.price - b.price,
			},
			{
				dataIndex: "description",
				key: "description",
				title: "Parking Spot Description",
				sorter: (a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0,
			}
		],
		lockerColumns: [
			{
				dataIndex: "lID",
				key: "lID",
				title: "Locker ID",
				sorter: (a, b) => a.lID < b.lID ? -1 : a.lID > b.lID ? 1 : 0,
			},
			{
				dataIndex: "name",
				key: "name",
				title: "Locker #",
				sorter: (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
			},
			{
				dataIndex: "type",
				key: "type",
				title: "Locker Type",
				sorter: (a, b) => a.type < b.type ? -1 : a.type > b.type ? 1 : 0,
			},
			{
				dataIndex: "price",
				key: "price",
				title: "Locker Price",
				sorter: (a, b) => a.price - b.price,
			},
			{
				dataIndex: "description",
				key: "description",
				title: "Locker Description",
				sorter: (a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0,
			}
		],
		bikeColumns: [
			{
				dataIndex: "bID",
				key: "bID",
				title: "Bike Rack ID",
				sorter: (a, b) => a.bID < b.bID ? -1 : a.bID > b.bID ? 1 : 0,
			},
			{
				dataIndex: "name",
				key: "name",
				title: "Bike Rack #",
				sorter: (a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
			},
			{
				dataIndex: "price",
				key: "price",
				title: "Bike Rack Price",
				sorter: (a, b) => a.price - b.price,
			},
			{
				dataIndex: "description",
				key: "description",
				title: "Bike Rack Description",
				sorter: (a, b) => a.description < b.description ? -1 : a.description > b.description ? 1 : 0,
			},
		],
		payoutColumns: [
			{
				title: "Payout Type",
				key: "payoutType",
				scopedSlots: { customRender: "payoutType" },
			},
			{
				title: "Payout Amount",
				key: "payoutAmount",
				scopedSlots: { customRender: "payoutAmount" },
			},
			{
				title: "Payout Date",
				key: "payoutDate",
				scopedSlots: { customRender: "payoutDate" },
			},
		]
	}),
	computed: {
		unitPremiums() {
			let unitPremiums = this.transaction.product.unit && this.transaction.product.unit.package.other && this.transaction.product.unit.package.other.premiums || {}
			let price = 0
			Object.values(unitPremiums).forEach(prm => {
				if (!prm.active) return
				if (prm.value) prm.value = +prm.value
				if (isNaN(prm.value)) return
				price += prm.value
			})
			return price
		},
		lotPremiums() {
			return this.transaction.offer.lotPremiums
		},
		lotPremiumsName() {
			if (this.product.lot && this.product.lot.other && this.product.lot.other.premiums) {
				const finalPremiums = this.product.lot.other.premiums
				return Object.values(finalPremiums).map(p => p.name).join(', ')
			}
			return ''
		},
		pkgPrice() {
			return this.transaction.product && this.transaction.product.unit && this.transaction.product.unit.package && this.transaction.product.unit.package.price || 0
		},
		msrp() {
			if (this.instance.productType === 'highrise') {
				return (this.pkgPrice + this.unitPremiums + this.transaction.offer.addOnsPrice + this.transaction.offer.nonInventoryAddOnsPrice - this.reservationAmount).toLocaleString('en-US')
			} else {
				return (this.pkgPrice + this.transaction.offer.lotPremiums - this.reservationAmount).toLocaleString('en-US')
			}
		},
		elevationImages() {
			if (!this.unit || !this.unit.package || !this.unit.package.floors) {
				return []
			}
			let floorImages = this.unit.package.floors.map(x => { return { name: 'Floor Plan: ' + x.name, image: x.image } })
			let mainImages = []

			if (this.instance.productType === 'highrise') {
				if (this.unit.package.terraceImage) {
					floorImages.push(this.unit.package.terraceImage)
				}
				if (this.unit.package.penthouseImage) {
					floorImages.push(this.unit.package.penthouseImage)
				}
			} else if (this.instance.productType === 'lowrise') {
				mainImages.push({ name: 'Featured Image', image: this.unit.image })
				if (this.transaction.offer?.bricks?.length) {
					mainImages.push({ name: 'Exterior Colour Package: ' + this.transaction.offer.bricks[0].name, image: this.transaction.offer.bricks[0].image });
				}
			}
			let result = [...mainImages, ...floorImages].filter(x => x)
			if (!result.length) {
				result = [{ name: 'Featured Image', image: this.unit.image }]
			}
			return result
		},
		productStatus() {
			return this.transaction.type === 'lease' ? 'Leased' : 'Sold'
		},
		isPurchase() {
			return this.transaction.type == 'purchase'
		},
		isLease() {
			return this.transaction.type == 'lease'
		},
		purchaseDate() {
			let offer = this.transaction.offer;
			let date = null;
			if (offer.purchaseDate) {
				date = offer.purchaseDate
			} else if (this.transaction.signedAt) {
				date = this.transaction.signedAt
			}

			return date;
		},
		balance() {
			let purchasePrice = this.transaction.offer.purchasePrice
			let deposits = 0
			this.deposits.forEach(dep => {
				deposits += dep.amount
			})
			return purchasePrice - deposits
		},
		addOnBalance() {
			let purchasePrice = this.transaction.offer.addOnsPrice + this.transaction.offer.nonInventoryAddOnsPrice || 0;
			let deposits = 0
			this.addOnDeposits.forEach(dep => {
				deposits += dep.amount
			})
			return purchasePrice - deposits
		},
		deposits() {
			let deps = this.transaction.offer.deposits || []
			return deps.filter(x => x.amount)
		},
		addOnDeposits() {
			let deps = this.transaction.offer.addOnDeposits || []
			return deps.filter(x => x.amount)
		},
		unit() {
			return this.product.unit
		},
		currentEnvelopes() {
			return this.$store.getters.currentEnvelopes
		},
		nextDeposit() {
			if (!this.transaction.offer.deposits || !this.transaction.offer.deposits.length) return 'N/A'

			let dateNow = Date.now()

			let nextDeposit
			let amountToPay

			this.transaction.offer.deposits.forEach(({ date, amount }) => {
				date = new Date(date).getTime()
				if (date > dateNow) {

					if (!nextDeposit) {
						nextDeposit = date
						amountToPay = amount
					} else if (date < nextDeposit) {
						nextDeposit = date
						amountToPay = amount
					}


				}
			})
			if (nextDeposit) {

				return `${formatDate(nextDeposit)}: $${formatNumber(amountToPay)}`

			} else {
				return 'N/A'
			}
		},
		isComplete() {
			let main = this.transaction.envelopes.find(d => d.main)
			if (!main) return false
			return main.status.incudes('complete')
		},
		transactionStatus() {
			let { status } = this.transaction
			let done = {}, pending = {}
			let envelopes = this.currentEnvelopes

			let color = 'success'
			let text = 'All Contracts Signed'

			if (status === 'pending' || status === 'created') {
				color = 'warning'
				text = 'Contracts Pending'

				let allPurchaserSigned = false;

				if (!this.transaction.isManual) {
					envelopes.forEach(e => {
						if (!e.esign) return
						if (e.status === 'voided') return
						if (e.main && e.progress && e.progress.length) {
							let totalPurchasers = 0;
							let totalSinged = 0
							e.progress.forEach(s => {
								if (s.roleName && s.roleName.startsWith('Purchaser #')) {
									totalPurchasers++;
									if (s.status === 'completed') {
										totalSinged++;
									}
								}
							})

							if (totalPurchasers === totalSinged) {
								allPurchaserSigned = true;
								text = 'Purchaser Signed';
								color = 'success'
							}
						}
					})
				}

				return { color, text, allPurchaserSigned }
			}
			if (status.includes('void')) {
				color = 'error'
				text = 'Transaction Voided'
				return { color, text }
			}



			if (this.transaction.isManual) {
				let main = this.transaction.envelopes.find(x => x.main)
				let hasPendingManual = this.transaction.envelopes.find(
					x => !x.main && x.esign && !x.status.includes('complete') && !x.status.includes('void')
				)

				color = 'warning'
				text = 'Main Agreement Pending'

				if (main && main.status.includes('void')) {
					return {
						color: 'error',
						text: 'Transaction Voided'
					}
				}
				if (hasPendingManual) {
					return {
						color: 'warning',
						text: 'Pending Amendments/Documents'
					}
				}
				if (main && main.status.includes('comple')) {
					return {
						color: 'success',
						text: 'All Contracts Signed',
						isComplete: true,
						isConditional: !this.transaction.isSigned
					}
				}
				return {
					color,
					text,
				}
			}

			if (!envelopes) return { color: 'warning', text: 'No main envelope found!' }

			envelopes.forEach(e => {
				if (!e.esign) return
				if (e.status === 'voided') return
				if (e.main) {
					if (e.signedSoFar === e.signFull) {
						done.main = true
					} else {
						pending.main = true
					}
				} else {
					if (e.signedSoFar === e.signFull) {
						done.others = true
					} else {
						pending.others = true
					}
				}
			})



			if (Object.keys(pending).length > 0) {
				color = 'processing'
				text = pending.main ? 'Main Contract Pending' : 'Additional Contract Pending'
			}


			return {
				color,
				text,
				isComplete: done.main,
				isConditional: !this.transaction.isSigned
			}
		},
		productType() {
			return this.instance.productType || 'lowrise'
		},
		instance() {
			return this.$store.state.instance
		},
		product() {
			return this.transaction.product || {}
		},
		purchasers() {
			return this.transaction.purchasers
		},
		transaction() {
			return this.$store.state.appData.currentTransaction
		},
		purchaseOfferLabel() {
			if (this.transaction.offerType === 'firm') return 'Firm'
			return 'Conditional Offer'
		},
		conditionalHTML() {
			let result = false
			if (this.transaction.offerType === 'conditional') {
				let { graceEndDate } = this.transaction
				if (!isNaN(graceEndDate) && graceEndDate > Date.now()) {
					let days = diffDays(graceEndDate)
					if (days >= 0) {
						days = days === 0 ? 'today' : `in ${days} day(s)`
					}
					result = `(Firms up ${days})`
				}
			}
			return result
		},
		graceDateLabel() {
			if (this.transaction.offerType === 'firm') return ''
			return 'Conditional Offer Date'
		},
		graceEndDate() {
			let date = false
			if (this.transaction.offerType === 'conditional') {
				let { graceEndDate } = this.transaction
				if (!isNaN(graceEndDate) && graceEndDate > Date.now()) {
					date = formatDate(graceEndDate);
				}
			}
			return date
		},
		worksheet() {
			return this.transaction.worksheet
		},
		reservation() {
			return this.transaction.reservation
		},
		reservationAmount() {
			return (this.worksheet && this.worksheet.reservation && this.worksheet.reservation.amount) || (this.reservation && this.reservation.amount) || 0;
		},

		commissionPayouts() {
			if (this.commissionPayout) {
				return this.commissionPayout.payouts.map((p, i) => {
					return {
						...p,
						id: i
					}
				})
			}
			return []
		},

        passportOther() {
			return this.$store.state.appData.passportOther
		},

        showInventoryAddOns() {
            return Object.values(this.product?.unit?.package?.other?.addons || {}).some(a => a?.length)
        },

        showNonInventoryAddOns() {
            return Object.values(this.product?.unit?.package?.other?.nonInventoryAddOns || {}).some(a => a?.length)
        },
	},
	methods: {
		formatDate, formatNumber, moment,
		async viewPurchaserDocs(purchaser) {
			this.purchaserImages = [];
			this.$store.commit('LOAD', true);
			if (purchaser.fileFront?.id && purchaser.fileBack?.id) {
				let result = await Promise.all([this.fetchFileSignedUrl(purchaser.fileFront.id), this.fetchFileSignedUrl(purchaser.fileBack.id)]);
				if (result[0]) {
					this.purchaserImages.push(result[0])
				}
				if (result[1]) {
					this.purchaserImages.push(result[1])
				}
			}
			if (purchaser.filePassport?.id) {
				const url = await this.fetchFileSignedUrl(purchaser.filePassport.id)
				if (url) {
					this.purchaserImages.push(url)
				}
			}
			this.$store.commit('LOAD', false);
			this.showPurchaserDocs = true;
		},

		async fetchFileSignedUrl(id) {
			try {
				const { data } = await this.$api.post(`upload/files/get-signed-url/${id}`)
				return data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching image url. Please try again.`))
				}
				return null
			}
		},

		async fetchCommissionPayouts() {
			try {
				const { data } = await this.$api.get(`/transactions/:instance/commission-payouts?transactionId=${this.$route.params.id}`)
				this.commissionPayout = data[0] || null;
			} catch (error) {
				this.$toastError(error, 'Error occurred while fetching agent commission payouts')
			}
		}
	},
	created() {
		this.fetchCommissionPayouts();
	}
}
</script>

<style lang="scss">
.carousel-modal>>>.slick-slide {
	text-align: center;
	line-height: 160px;
	background: #364d79;
	overflow: hidden;
}

.carousel-modal .custom-slick-arrow {
	background: white;
	border: 7px solid white;
	box-sizing: content-box;
	border-radius: 100%;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);

	&:hover {
		background: white;
	}
}

.carousel-modal .slick-track {
	display: flex;
	align-items: center;
}

.carousel-modal>>>.slick-slide h3 {
	color: #fff;
}

.carousel-modal {
	.slick-dots li button {
		background: var(--med-gray);
	}

	.slick-dots li.slick-active button {
		background: gray;
	}
}
</style>
