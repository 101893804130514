<template>
	<div class="w-full timelinePage">
		<div class="dF aC jE mb-3" style="gap: 10px;" v-if="timeline.length">
			<div class="dF aC" style="gap: 10px;">
				<div class="text-md">Sort by:</div>
				<a-select v-model="sortBy" style="width: 300px" size="large">
					<a-select-option v-for="sort in sortOptionList" :key="sort.value" :value="sort.value">
						{{ sort.name }}
					</a-select-option>
				</a-select>
			</div>
		</div>
		<a-timeline class="timeline mt-3">
			<div v-for="(activity) in timeline" :key="activity.id" class="dF text-md">
				<div class="dateTime">
					{{ $formatDate(activity.createdAt, 'lll') }}
				</div>
				<a-timeline-item>
					<div class="activity-details">
						{{ activity.logNote }}
					</div>
				</a-timeline-item>
			</div>
		</a-timeline>
	</div>
</template>

<script>

export default {
	data() {
		return {
			sortBy: 'DESC',
			sortOptionList: [
				{ name: 'Date (Newest First)', value: 'DESC' },
				{ name: 'Date (Oldest First)', value: 'ASC' },
			],
		}
	},
	computed: {
		user() {
			return this.$store.state.user?.user;
		},

		timeline() {
			return (this.$store.state.appData.currentTransaction?.timelines || []).map((t) => {
				const { data, user } = t;

				const currentUser = this.user;
				let userName = '';
				if (currentUser?.id && user?.id) {
					userName = currentUser.id === user.id ? 'You' : `${user.firstName} ${user.lastName}`;
				}

				const { action = 'interacted with', purchaser } = data;

				const logNote = purchaser
					? `${userName} ${action} (${purchaser.firstName} ${purchaser.lastName} - ${purchaser.email})`
					: `${userName} ${action}`;

				return {
					...t,
					logNote,
				};
			}).sort((a, b) => {
				if (this.sortBy === 'DESC') {
					return new Date(b.createdAt) - new Date(a.createdAt);
				} else {
					return new Date(a.createdAt) - new Date(b.createdAt);
				}
			});
		},
	},
}
</script>

<style lang="scss" scoped>
.timelinePage {
	background-color: white;
	border: 1px solid #E4E7EB;
	padding: 10px 20px;
}

.dateTime {
	color: #9EA0A5;
	width: 200px;
	line-height: 12px;
	padding-left: 10px;
}

.activity-details {
	margin-left: 10px;
	font-size: medium;
}
</style>
