<template>
	<div v-if="transaction">
		<vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="false"
			pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
				margin: 12,
				image: { type: 'jpeg', quality: 2 },
				html2canvas: {
					useCORS: true,
					scale: 2
				}
			}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
			<section slot="pdf-content">
				<div v-if="transaction" class="text-dark">
					<h4 class="text-center">{{ transactionHeader }}</h4>
					<a-divider class="mt-2" style="background: black; margin: 10px 0px"></a-divider>

					<div class="contract-details-card general-summary">
						<a-alert v-if="showAmendmentNote" show-icon type="info" class="my-3">
							<template #message>
								<div>
									<strong>Important:</strong> An amendment(s) has been made and finalized which may impact the terms of this Agreement. Please review the amendment(s) in the <span class="link"> documents section </span> for more information.
								</div>
							</template>
						</a-alert>
						<div class="w-full dF jSB aC ant-descriptions-title">
							Offer Details
							<div>
								<small style="font-weight:300; background:var(--off-white-dark); border-radius:40px;"
									class="px-3 py-2 inline-block">
									<a-badge :status="transactionStatus.color" :text="transactionStatus.text" />
								</small>
								<small style="font-weight:300; background:var(--off-white-dark); border-radius:40px;"
									class="px-3 py-2 inline-block ml-3" v-if="transactionStatus.isComplete">
									<a-badge status="error"
										:text="transactionStatus.isConditional ? productStatus + ' Conditional' : productStatus" />
								</small>
								<small style="font-weight:300; background:var(--off-white-dark); border-radius:40px;"
									class="px-3 py-2 inline-block ml-3"
									v-else-if="transactionStatus.allPurchaserSigned">
									<a-badge color="purple" :text="productStatus + ' Conditional'" />
								</small>
							</div>
						</div>

						<a-card v-if="productType === 'lowrise' && product.lot">
							<a-descriptions layout="horizontal" style="font-size: 14px">
								<div slot="title" class="w-full dF jSB">
									Summary
									<small
										style="font-weight:300; background:var(--off-white-dark); border-radius:40px;"
										class="px-3 py-2">Created on {{ formatDate(transaction.createdAt) }}</small>
								</div>
								<a-descriptions-item label="Offer Date">
									{{ formatDate(transaction.createdAt) }}
								</a-descriptions-item>
								<a-descriptions-item label="Product">
									Lot {{ product.lot && product.lot.name }}{{ product?.lot?.block ? ` (Block ${product.lot.block})` : '' }} ({{ unit && unit.unitGroup &&
										unit.unitGroup.name }} {{ unit.name }})
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Elevation Price">
									${{ formatNumber(pkgPrice || 0) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Premium Price">
									${{ formatNumber(lotPremiums || 0) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Suggested MSRP">
									${{ formatNumber(msrp || 0) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Purchase Price">
									${{ formatNumber(transaction.offer.purchasePrice) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="purchaseDate" label="Purchase Date">
									{{ formatDate(purchaseDate) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isLease" label="Monthly Lease Rate">
									${{ formatNumber(transaction.offer.purchasePrice) }}
								</a-descriptions-item>

								<a-descriptions-item v-if="isPurchase" label="Irrevocable Date">
									{{ !transaction.offer.irrevocableDate || transaction.offer.irrevocableDate === 'N/A'
										?
										'N/A' :
										formatDate(transaction.offer.irrevocableDate) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Closing Date">
									{{ moment(transaction.offer.closingDate).isValid() ? formatDate(transaction.offer.closingDate) : 'N/A' }}
									({{ (transaction.offer.closingType && transaction.offer.closingType[0].toUpperCase()) + transaction.offer.closingType.substr(1) }})
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Offer Type">
									<span>{{ purchaseOfferLabel }}&nbsp;<strong v-if="conditionalHTML"
											v-html="conditionalHTML"></strong></span>
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase && graceDateLabel && graceEndDate"
									:label="graceDateLabel">
									<span>{{ graceEndDate }}</span>
								</a-descriptions-item>
							</a-descriptions>
						</a-card>

						<a-card v-else-if="productType === 'highrise' && product.unit">
							<a-descriptions layout="horizontal" style="font-size: 14px">
								<div slot="title" class="w-full dF jSB">
									Summary
									<small
										style="font-weight:300; background:var(--off-white-dark); border-radius:40px;"
										class="px-3 py-2">Created on {{ formatDate(transaction.createdAt) }}</small>
								</div>
								<a-descriptions-item label="Offer Date">
									{{ formatDate(transaction.createdAt) }}
								</a-descriptions-item>
								<a-descriptions-item label="Unit">
									{{ product.floor && product.floor.name }} - Unit #{{ product.unit &&
										product.unit.unitNumber }} ({{ product.unit && product.unit.name }})
								</a-descriptions-item>
                                <a-descriptions-item label="Legal Level" v-if="product.unit.legalLevel">
                					{{ product.unit.legalLevel }}
                				</a-descriptions-item>
								<a-descriptions-item label="Add Ons">
									<span
										v-if="product?.unit?.package?.other?.addons">
										Parking - {{ product.unit.package.other.addons.parking &&
											product.unit.package.other.addons.parking.length || 0 }}, Lockers -
										{{ product.unit.package.other.addons.lockers &&
											product.unit.package.other.addons.lockers.length || 0 }}, Bike Racks -
										{{ product.unit.package.other.addons.bikeRacks &&
											product.unit.package.other.addons.bikeRacks.length || 0 }}
									</span>
								</a-descriptions-item>
                                <a-descriptions-item label="Non Inventory Add Ons" v-if="product?.unit?.package?.other?.nonInventoryAddOns">
									<span>
										Parking - {{ product.unit.package.other.nonInventoryAddOns.parking &&
											product.unit.package.other.nonInventoryAddOns.parking.length || 0 }}, Lockers -
										{{ product.unit.package.other.nonInventoryAddOns.lockers &&
											product.unit.package.other.nonInventoryAddOns.lockers.length || 0 }}, Bike Racks -
										{{ product.unit.package.other.nonInventoryAddOns.bikeRacks &&
											product.unit.package.other.nonInventoryAddOns.bikeRacks.length || 0 }}
									</span>
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Unit Price">
									${{ formatNumber(pkgPrice || 0) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Add Ons Price">
									${{ formatNumber(transaction.offer.addOnsPrice || 0) }}
								</a-descriptions-item>
                                <a-descriptions-item v-if="isPurchase && transaction.offer.nonInventoryAddOnsPrice" label="Non Inventory Add Ons Price">
            						${{ formatNumber(transaction.offer.nonInventoryAddOnsPrice || 0) }}
            					</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Premium Price">
									${{ formatNumber(unitPremiums || 0) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Suggested MSRP">
									${{ formatNumber(msrp || 0) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Purchase Price">
									${{ formatNumber(transaction.offer.purchasePrice) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="purchaseDate" label="Purchase Date">
									{{ formatDate(purchaseDate) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isLease" label="Monthly Lease Rate">
									${{ formatNumber(transaction.offer.totalRent) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="reservationAmount" label="Reservation Fee">
									${{ formatNumber(reservationAmount) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isLease" label="Tenancy Start Date">
									{{ transaction.offer.tenancyStartDate &&
										formatDate(transaction.offer.tenancyStartDate)
									}}
								</a-descriptions-item>
								<a-descriptions-item v-if="isLease" label="Tenancy End Date">
									{{ transaction.offer.tenancyEndDate && formatDate(transaction.offer.tenancyEndDate)
									}}
								</a-descriptions-item>
								<a-descriptions-item v-if="isLease" label="Lease Deposit">
									${{ formatNumber(transaction.offer.deposit) }}
								</a-descriptions-item>

								<a-descriptions-item v-if="isPurchase" label="Irrevocable Date">
									{{ !transaction.offer.irrevocableDate || transaction.offer.irrevocableDate === 'N/A'
										?
										'N/A' :
										formatDate(transaction.offer.irrevocableDate) }}
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Closing Date">
									{{ moment(transaction.offer.closingDate).isValid() ? formatDate(transaction.offer.closingDate) : 'N/A' }}
                                    ({{ (transaction.offer.closingType && transaction.offer.closingType[0].toUpperCase()) + transaction.offer.closingType.substr(1) }})
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase" label="Offer Type">
									<span>{{ purchaseOfferLabel }}&nbsp;<strong v-if="conditionalHTML"
											v-html="conditionalHTML"></strong></span>
								</a-descriptions-item>
								<a-descriptions-item v-if="isPurchase && graceDateLabel && graceEndDate"
									:label="graceDateLabel">
									<span>{{ graceEndDate }}</span>
								</a-descriptions-item>
							</a-descriptions>
						</a-card>
					</div>

					<div v-if="purchasers && purchasers.length" class="contract-details-card mt-4 pdf-page-break">
						<div class="w-full dF jSB aC ant-descriptions-title">
							Purchasers Details
						</div>

						<a-card>
							<div v-for="(purchaser, index) in purchasers" :key="index" :class="index > 0 ? 'pdf-page-break' : ''">
								<a-descriptions layout="horizontal" style="font-size: 14px">
									<div slot="title" class="w-full dF jSB aC">
										Purchaser #{{ index + 1 }}
									</div>
									<a-descriptions-item label="First Name">
										{{ purchaser.firstName }}
									</a-descriptions-item>
									<a-descriptions-item label="Last Name">
										{{ purchaser.lastName }}
									</a-descriptions-item>
									<a-descriptions-item label="Middle Name">
										{{ purchaser.middleName }}
									</a-descriptions-item>
									<a-descriptions-item label="Email">
										{{ purchaser.email }}
									</a-descriptions-item>
									<a-descriptions-item label="Phone Number">
										{{ purchaser.phone }}
									</a-descriptions-item>
									<a-descriptions-item label="Date of Birth">
										{{ purchaser.dob }}
									</a-descriptions-item>
									<a-descriptions-item label="Date of Birth">
										{{ purchaser.dob }}
									</a-descriptions-item>
									<a-descriptions-item label="Business Phone#">
										{{ purchaser.business }}
									</a-descriptions-item>
									<a-descriptions-item label="Cell Phone#">
										{{ purchaser.cell }}
									</a-descriptions-item>
									<a-descriptions-item label="Company">
										{{ purchaser.company }}
									</a-descriptions-item>
									<a-descriptions-item label="Occupation">
										{{ purchaser.occupation }}
									</a-descriptions-item>
									<a-descriptions-item label="Job Title">
										{{ purchaser.jobTitle }}
									</a-descriptions-item>
									<a-descriptions-item label="SSN/SIN #">
										{{ purchaser.ssnsin }}
									</a-descriptions-item>
									<a-descriptions-item label="Driver License #">
										{{ purchaser.idValue }}
									</a-descriptions-item>
									<a-descriptions-item label="License Issuing Jurisdiction">
										{{ purchaser.issuing }}
									</a-descriptions-item>
									<a-descriptions-item label="License Expiry">
										{{ purchaser.expiry }}
									</a-descriptions-item>
									<a-descriptions-item label="Passport">
										{{ purchaser.passportOtherValue }}
									</a-descriptions-item>
									<a-descriptions-item label="Passport Issuing Jurisdiction">
										{{ purchaser.passportOtherIssuing }}
									</a-descriptions-item>
									<a-descriptions-item label="Passport Issuing Jurisdiction - Country">
										{{ purchaser.passportOtherIssuingCountry }}
									</a-descriptions-item>
									<a-descriptions-item label="Passport Expiry">
										{{ purchaser.passportOtherExpiry }}
									</a-descriptions-item>
									<template v-if="purchaser.bcValue">
										<a-descriptions-item label="Birth Certificate">
											{{ purchaser.bcValue }}
										</a-descriptions-item>
										<a-descriptions-item label="Birth Certificate Issuing Jurisdiction">
											{{ purchaser.bcIssuing }}
										</a-descriptions-item>
										<a-descriptions-item label="Birth Certificate Issuing Jurisdiction - Country">
											{{ purchaser.bcIssuingCountry }}
										</a-descriptions-item>
										<a-descriptions-item label="Birth Place">
											{{ purchaser.birthPlace }}
										</a-descriptions-item>
										<a-descriptions-item label="Birth Certificate Date of Registration">
											{{ purchaser.registrationDate }}
										</a-descriptions-item>
										<a-descriptions-item label="Birth Certificate Date Issued">
											{{ purchaser.issueDate }}
										</a-descriptions-item>
									</template>
									<template v-if="purchaser.prValue">
										<a-descriptions-item label="PR">
											{{ purchaser.prValue }}
										</a-descriptions-item>
										<a-descriptions-item label="PR Issuing Jurisdiction">
											{{ purchaser.prIssuing }}
										</a-descriptions-item>
										<a-descriptions-item label="PR Issuing Jurisdiction - Country">
											{{ purchaser.prIssuingCountry }}
										</a-descriptions-item>
										<a-descriptions-item label="PR Expiry">
											{{ purchaser.prExpiry }}
										</a-descriptions-item>
									</template>
									<template v-if="purchaser.wpValue">
										<a-descriptions-item label="Work Permit">
											{{ purchaser.wpValue }}
										</a-descriptions-item>
										<a-descriptions-item label="Work Permit Issuing Jurisdiction">
											{{ purchaser.wpIssuing }}
										</a-descriptions-item>
										<a-descriptions-item label="Work Permit Issuing Jurisdiction - Country">
											{{ purchaser.wpIssuingCountry }}
										</a-descriptions-item>
										<a-descriptions-item label="Work Permit Expiry">
											{{ purchaser.wpExpiry }}
										</a-descriptions-item>
									</template>
									<template v-if="purchaser.otherValue">
										<a-descriptions-item :label="purchaser.otherName">
											{{ purchaser.otherValue }}
										</a-descriptions-item>
										<a-descriptions-item :label="`${purchaser.otherName} Issuing Jurisdiction`">
											{{ purchaser.otherIssuing }}
										</a-descriptions-item>
										<a-descriptions-item :label="`${purchaser.otherName} Issuing Jurisdiction - Country`">
											{{ purchaser.otherIssuingCountry }}
										</a-descriptions-item>
										<a-descriptions-item :label="`${purchaser.otherName} Expiry`">
											{{ purchaser.otherExpiry }}
										</a-descriptions-item>
									</template>
									<a-descriptions-item label="Address 1" :span="2">
										{{ purchaser.address1 }}
									</a-descriptions-item>
									<a-descriptions-item label="Address 2">
										{{ purchaser.address2 }}
									</a-descriptions-item>
									<a-descriptions-item label="City">
										{{ purchaser.city }}
									</a-descriptions-item>
									<a-descriptions-item label="State/Province">
										{{ purchaser.region }}
									</a-descriptions-item>
									<a-descriptions-item label="Zip/Postal Code">
										{{ purchaser.postal }}
									</a-descriptions-item>
									<a-descriptions-item label="Country">
										{{ purchaser.country }}
									</a-descriptions-item>
								</a-descriptions>
								<a-divider v-if="(purchasers.length - 1) !== index" class="mt-3"
									style="background: black; margin: 5px 0px 10px"></a-divider>
							</div>
						</a-card>
					</div>

					<div v-if="isPurchase" class="contract-details-card mt-4 pdf-page-break">
						<div class="w-full dF jSB aC ant-descriptions-title">
							Deposit Structure
						</div>
						<a-descriptions layout="vertical" bordered class="bg-white" style="font-size: 14px">
							<a-descriptions-item :label="`Deposit #${depI + 1}`" v-for="(dep, depI) in deposits"
								:key="depI + 'deposit'">
								${{ dep.amount.toLocaleString(undefined, {maximumFractionDigits: 2}) }} on {{ formatDateString(dep.date) }}
							</a-descriptions-item>
							<a-descriptions-item label="Balance Due on Closing">
								${{ balance.toLocaleString(undefined, {maximumFractionDigits: 2}) }} {{ moment(transaction.offer.closingDate).isValid() ? 'on ' + formatDate(transaction.offer.closingDate) : '' }}
							</a-descriptions-item>
						</a-descriptions>
					</div>

					<div class="contract-details-card mt-4 pdf-page-break">
						<div class="w-full dF jSB aC ant-descriptions-title">
							{{ productType === 'lowrise' ? 'Product Details' : 'Unit Details' }}
						</div>
						<a-descriptions layout="vertical" bordered class="bg-white"
							v-if="productType === 'lowrise' && product.lot" style="font-size: 14px">
							<a-descriptions-item label="Lot#">
								Lot {{ product.lot && product.lot.name }} {{ product?.lot?.block ? ` (Block ${product.lot.block})` : '' }}
							</a-descriptions-item>
							<a-descriptions-item label="Lot Premiums">
								{{ lotPremiumsName }}
							</a-descriptions-item>
							<a-descriptions-item label="Premium Value">
								${{ formatNumber(transaction.offer.lotPremiums) }}
							</a-descriptions-item>
							<a-descriptions-item label="Model/Elevation">
								{{ unit && unit.unitGroup && unit.unitGroup.name }} {{ unit.name }}
							</a-descriptions-item>

							<a-descriptions-item label="Base Price">
								${{ formatNumber(transaction.offer.basePrice) }}
							</a-descriptions-item>
							<a-descriptions-item>
								<div slot="label" class="dF" style="gap: 25px">
									<div>Chosen Floorplan Package</div>
									<div v-if="transaction.offer?.bricks?.length"> PreferredExterior Color Package</div>
								</div>
								<div class="dF" style="gap: 25px">
									<div v-if="unit.package">
										<strong>{{ unit.package.name }}</strong> <br />
										{{ unit.package.beds }} Beds, <br />
										{{ unit.package.baths }} Baths, <br />
										{{ unit.package.garages }} Garages, <br />
										{{ unit.package.sqft }} Sqft <br />
									</div>
									<div v-if="transaction.offer?.bricks?.length">
										<div v-for="(b, index) in transaction.offer.bricks" :key="index">
											<strong>{{ b.name }}{{ index == 0 ? ' (Preferred)' : '(Alternate)' }}</strong>
										</div>
									</div>
								</div>
							</a-descriptions-item>
						</a-descriptions>

						<a-descriptions layout="vertical" bordered class="bg-white"
							v-if="productType === 'highrise' && product.unit" style="font-size: 14px">
							<a-descriptions-item label="Floor">
								{{ product.floor.name }}
							</a-descriptions-item>
							<a-descriptions-item label="Unit#">
								{{ 'Unit #' + (product.unit && product.unit.unitNumber) }}
							</a-descriptions-item>
							<a-descriptions-item label="Unit Code">
								{{ product.unit.unitCode }}
							</a-descriptions-item>
							<a-descriptions-item label="Marketing Name">
								{{ product.unit.name }}
							</a-descriptions-item>
                            <a-descriptions-item label="Legal Level" v-if="product.unit.legalLevel">
            					{{ product.unit.legalLevel }}
            				</a-descriptions-item>
							<a-descriptions-item label="Unit Price"
								v-if="product.unit.packages && product.unit.packages.length">
								${{ formatNumber(product.unit.packages[0].price) }}
							</a-descriptions-item>
							<a-descriptions-item>
								<div slot="label" class="dF jSB">
									Unit Information
								</div>

								{{ unit.packages[0].beds }} Beds, <br />
								{{ unit.packages[0].baths }} Baths, <br />
								{{ unit.packages[0].sqft }} Sqft, <br />
								{{ unit.packages[0].exposure }} View <br />
							</a-descriptions-item>
						</a-descriptions>
					</div>

					<div v-if="isPurchase && productType === 'highrise' && addOnDeposits.length"
						class="contract-details-card mt-4 pdf-page-break">
						<div class="w-full dF jSB aC ant-descriptions-title">
							Add Ons Deposit Structure
						</div>

						<a-descriptions layout="vertical" bordered class="bg-white" style="font-size: 14px">
							<a-descriptions-item :label="`Deposit #${depI + 1}`" v-for="(dep, depI) in addOnDeposits"
								:key="depI + 'deposit'">
								${{ dep.amount.toLocaleString(undefined, {maximumFractionDigits: 2}) }} on {{ formatDateString(dep.date) }}
							</a-descriptions-item>
							<a-descriptions-item label="Balance Due on Closing">
								${{ balance.toLocaleString(undefined, {maximumFractionDigits: 2}) }} {{ moment(transaction.offer.closingDate).isValid() ? 'on ' + formatDate(transaction.offer.closingDate) : '' }}
							</a-descriptions-item>
						</a-descriptions>
					</div>

					<div class="mt-4 pdf-page-break"
						v-if="productType === 'highrise' && showInventoryAddOns">
						<div class="w-full dF jSB aC ant-descriptions-title">
							Inventory Add Ons Details
						</div>

						<div style="background-color: white; padding: 10px" class="pb-5">
							<a-table :rowKey="(e) => e.id" :columns="parkingColumns"
								:data-source="product.unit.package.other.addons.parking || []" :pagination="false" />
							<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
							<a-table :rowKey="(e) => e.id" :columns="lockerColumns"
								:data-source="product.unit.package.other.addons.lockers || []" :pagination="false" />
							<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
							<a-table :rowKey="(e) => e.id" :columns="bikeColumns"
								:data-source="product.unit.package.other.addons.bikeRacks || []" :pagination="false" />
						</div>
					</div>

                    <div class="mt-4 mb-5 pdf-page-break"
            			v-if="productType === 'highrise' && showNonInventoryAddOns">
            			<div class="w-full dF jSB aC ant-descriptions-title">
            				Non Inventory Add Ons Details
            			</div>

            			<div style="background-color: white; padding: 10px" class="pb-5">
							<a-table :rowKey="(e) => e.id" :columns="parkingColumns"
								:data-source="product.unit.package.other.nonInventoryAddOns.parking || []" :pagination="false" />
							<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
							<a-table :rowKey="(e) => e.id" :columns="lockerColumns"
								:data-source="product.unit.package.other.nonInventoryAddOns.lockers || []" :pagination="false" />
							<a-divider class="mt-3" style="background: black; margin: 5px 0px 10px"></a-divider>
							<a-table :rowKey="(e) => e.id" :columns="bikeColumns"
								:data-source="product.unit.package.other.nonInventoryAddOns.bikeRacks || []" :pagination="false" />
						</div>
            		</div>

					<div v-if="elevationImages?.length" class="pdf-page-break">
						<div class="mt-4">
							<div class="w-full dF jSB aC ant-descriptions-title">
								{{ productType === 'lowrise' ? 'Elevation Images' : 'Floorplan Images' }}
							</div>
							<div v-for="(elevation, imgI) in elevationImages" :key="imgI" class="text-center">
								<h5> {{ elevation.name }} </h5>
								<img :src="elevation.image" alt="elevation"
									style="max-width: 550px; max-height: 550px;" />
								<div v-if="(elevationImages.length - 1) !== imgI" class="html2pdf__page-break mb-5" />
							</div>
						</div>
					</div>

					<div v-if="purchaserImages?.length" class="pdf-page-break">
						<div class="mt-4">
							<div class="w-full dF jSB aC ant-descriptions-title">
								Purchaser's Scanned Documents
							</div>
							<div v-for="(document, imgI) in purchaserImages" :key="document.image + imgI"
								class="text-center">
								<h5> {{ document.name }} </h5>
								<img :src="document.image" alt="elevation"
									style="max-width: 550px; max-height: 550px;" />
								<div v-if="(purchaserImages.length - 1) !== imgI" class="html2pdf__page-break mb-5" />
							</div>
						</div>
					</div>
				</div>
			</section>
		</vue-html2pdf>
	</div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { formatDate, formatNumber, diffDays } from 'bh-mod'
import moment from 'moment'

export default {
	components: {
		VueHtml2pdf
	},
	props: {
		download: {
			type: Boolean,
			default: false
		},
		showAmendmentNote: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			purchaserImages: [],
			parkingColumns: [
				{
					dataIndex: "pID",
					key: "pID",
					title: "Parking ID",
				},
				{
					dataIndex: "name",
					key: "name",
					title: "Parking Spot #",
				},
				{
					dataIndex: "type",
					key: "type",
					title: "Parking Spot Type",
				},
				{
					dataIndex: "price",
					key: "price",
					title: "Parking Spot Price",
				},
				{
					dataIndex: "description",
					key: "description",
					title: "Parking Spot Description",
				}
			],
			lockerColumns: [
				{
					dataIndex: "lID",
					key: "lID",
					title: "Locker ID",
				},
				{
					dataIndex: "name",
					key: "name",
					title: "Locker #",
				},
				{
					dataIndex: "type",
					key: "type",
					title: "Locker Type",
				},
				{
					dataIndex: "price",
					key: "price",
					title: "Locker Price",
				},
				{
					dataIndex: "description",
					key: "description",
					title: "Locker Description",
				}
			],
			bikeColumns: [
				{
					dataIndex: "bID",
					key: "bID",
					title: "Bike Rack ID",
				},
				{
					dataIndex: "name",
					key: "name",
					title: "Bike Rack #",
				},
				{
					dataIndex: "price",
					key: "price",
					title: "Bike Rack Price",
				},
				{
					dataIndex: "description",
					key: "description",
					title: "Bike Rack Description",
				},
			],
		}
	},

	computed: {
		unitPremiums() {
			let unitPremiums = this.transaction.product.unit && this.transaction.product.unit.package.other && this.transaction.product.unit.package.other.premiums || {}
			let price = 0
			Object.values(unitPremiums).forEach(prm => {
				if (!prm.active) return
				if (prm.value) prm.value = +prm.value
				if (isNaN(prm.value)) return
				price += prm.value
			})
			return price
		},
		lotPremiums() {
			return this.transaction.offer.lotPremiums
		},
		lotPremiumsName() {
			if (this.product.lot && this.product.lot.other && this.product.lot.other.premiums) {
				const finalPremiums = this.product.lot.other.premiums
				return Object.values(finalPremiums).map(p => p.name).join(', ')
			}
			return ''
		},
		pkgPrice() {
			return this.transaction.product && this.transaction.product.unit && this.transaction.product.unit.package && this.transaction.product.unit.package.price || 0
		},
		msrp() {
			if (this.instance.productType === 'highrise') {
				return (this.pkgPrice + this.unitPremiums + this.transaction.offer.addOnsPrice + this.transaction.offer.nonInventoryAddOnsPrice - this.reservationAmount).toLocaleString('en-US')
			} else {
				return (this.pkgPrice + this.transaction.offer.lotPremiums - this.reservationAmount).toLocaleString('en-US')
			}
		},
		elevationImages() {
			if (!this.unit || !this.unit.package || !this.unit.package.floors) {
				return []
			}
			let floorImages = this.unit.package.floors.map(x => { return { name: 'Floor Plan: ' + x.name, image: x.image } })
			let mainImages = []

			if (this.instance.productType === 'highrise') {
				if (this.unit.package.terraceImage) {
					floorImages.push(this.unit.package.terraceImage)
				}
				if (this.unit.package.penthouseImage) {
					floorImages.push(this.unit.package.penthouseImage)
				}
			} else if (this.instance.productType === 'lowrise') {
				mainImages.push({ name: 'Featured Image', image: this.unit.image })
				if (this.transaction.offer?.bricks?.length) {
					mainImages.push({ name: 'Exterior Colour Package: ' + this.transaction.offer.bricks[0].name, image: this.transaction.offer.bricks[0].image });
				}
			}
			let result = [...mainImages, ...floorImages].filter(x => x)
			if (!result.length) {
				result = [this.unit.image]
			}
			return result
		},
		productStatus() {
			return this.transaction.type === 'lease' ? 'Leased' : 'Sold'
		},
		isPurchase() {
			return this.transaction.type == 'purchase'
		},
		isLease() {
			return this.transaction.type == 'lease'
		},
		purchaseDate() {
			let offer = this.transaction.offer;
			let date = null;
			if (offer.purchaseDate) {
				date = offer.purchaseDate
			} else if (this.transaction.signedAt) {
				date = this.transaction.signedAt
			}

			return date;
		},
		balance() {
			let purchasePrice = this.transaction.offer.purchasePrice
			let deposits = 0
			this.deposits.forEach(dep => {
				deposits += dep.amount
			})
			return purchasePrice - deposits
		},
		addOnBalance() {
			let purchasePrice = this.transaction.offer.addOnsPrice + this.transaction.offer.nonInventoryAddOnsPrice || 0;
			let deposits = 0
			this.addOnDeposits.forEach(dep => {
				deposits += dep.amount
			})
			return purchasePrice - deposits
		},
		deposits() {
			let deps = this.transaction.offer.deposits || []
			return deps.filter(x => x.amount)
		},
		addOnDeposits() {
			let deps = this.transaction.offer.addOnDeposits || []
			return deps.filter(x => x.amount)
		},
		unit() {
			return this.product.unit || {};
		},
		currentEnvelopes() {
			return this.$store.getters.currentEnvelopes
		},
		nextDeposit() {
			if (!this.transaction.offer || !this.transaction.offer.deposits || !this.transaction.offer.deposits.length) return 'N/A'
			let dateNow = Date.now()
			let nextDeposit
			let amountToPay

			this.transaction.offer.deposits.forEach(({ date, amount }) => {
				date = new Date(date).getTime()
				if (date > dateNow) {
					if (!nextDeposit) {
						nextDeposit = date
						amountToPay = amount
					} else if (date < nextDeposit) {
						nextDeposit = date
						amountToPay = amount
					}
				}
			})
			if (nextDeposit) {
				return `${formatDate(nextDeposit)}: $${formatNumber(amountToPay)}`
			} else {
				return 'N/A'
			}
		},
		isComplete() {
			let main = this.transaction.envelopes.find(d => d.main)
			if (!main) return false
			return main.status.incudes('complete')
		},
		transactionStatus() {
			let { status } = this.transaction
			let done = {}, pending = {}
			let envelopes = this.currentEnvelopes

			let color = 'success'
			let text = 'All Contracts Signed'

			if (status === 'pending' || status === 'created') {
				color = 'warning'
				text = 'Contracts Pending'
				let allPurchaserSigned = false;

				if (!this.transaction.isManual) {
					envelopes.forEach(e => {
						if (!e.esign) return
						if (e.status === 'voided') return
						if (e.main && e.progress && e.progress.length) {
							let totalPurchasers = 0;
							let totalSinged = 0
							e.progress.forEach(s => {
								if (s.roleName && s.roleName.startsWith('Purchaser #')) {
									totalPurchasers++;
									if (s.status === 'completed') {
										totalSinged++;
									}
								}
							})

							if (totalPurchasers === totalSinged) {
								allPurchaserSigned = true;
								text = 'Purchaser Signed';
								color = 'success'
							}
						}
					})
				}
				return { color, text, allPurchaserSigned }
			}
			if (status && status.includes('void')) {
				color = 'error'
				text = 'Transaction Voided'
				return { color, text }
			}

			if (this.transaction.isManual) {
				let main = this.transaction.envelopes.find(x => x.main)
				let hasPendingManual = this.transaction.envelopes.find(
					x => x && !x.main && x.esign && x.status && !x.status.includes('complete') && !x.status.includes('void')
				)
				color = 'warning'
				text = 'Main Agreement Pending'

				if (main && main.status && main.status.includes('void')) {
					return {
						color: 'error',
						text: 'Transaction Voided'
					}
				}
				if (hasPendingManual) {
					return {
						color: 'warning',
						text: 'Pending Amendments/Documents'
					}
				}
				if (main && main.status && main.status.includes('complete')) {
					return {
						color: 'success',
						text: 'All Contracts Signed',
						isComplete: true,
						isConditional: !this.transaction.isSigned
					}
				}
				return {
					color,
					text,
				}
			}

			if (!envelopes) return { color: 'warning', text: 'No main envelope found!' }

			envelopes.forEach(e => {
				if (!e.esign) return
				if (e.status === 'voided') return
				if (e.main) {
					if (e.signedSoFar === e.signFull) {
						done.main = true
					} else {
						pending.main = true
					}
				} else {
					if (e.signedSoFar === e.signFull) {
						done.others = true
					} else {
						pending.others = true
					}
				}
			})

			if (Object.keys(pending).length > 0) {
				color = 'processing'
				text = pending.main ? 'Main Contract Pending' : 'Additional Contract Pending'
			}

			return {
				color,
				text,
				isComplete: done.main,
				isConditional: !this.transaction.isSigned
			}
		},
		productType() {
			return this.instance.productType || 'lowrise'
		},
		instance() {
			return this.$store.state.instance
		},
		product() {
			return this.transaction.product || {};
		},
		purchasers() {
			return this.transaction.purchasers || [];
		},
		transaction() {
			return this.$store.state.appData.currentTransaction
		},
		purchaseOfferLabel() {
			if (this.transaction.offerType === 'firm') return 'Firm'
			return 'Conditional Offer'
		},
		conditionalHTML() {
			let result = false
			if (this.transaction.offerType === 'conditional') {
				let { graceEndDate } = this.transaction
				if (!isNaN(graceEndDate) && graceEndDate > Date.now()) {
					let days = diffDays(graceEndDate)
					if (days >= 0) {
						days = days === 0 ? 'today' : `in ${days} day(s)`
					}
					result = `(Firms up ${days})`
				}
			}
			return result
		},
		graceDateLabel() {
			if (this.transaction.offerType === 'firm') return ''
			return 'Conditional Offer Date'
		},
		graceEndDate() {
			let date = false
			if (this.transaction.offerType === 'conditional') {
				let { graceEndDate } = this.transaction
				if (graceEndDate && !isNaN(graceEndDate) && graceEndDate > Date.now()) {
					date = formatDate(graceEndDate);
				}
			}
			return date
		},
		transactionHeader() {
			const { productType } = this.instance;
			const { product } = this.transaction;

			if (productType === 'highrise') {
				if (product && product.unit) {
					const { floor, unit } = product;
					return `${floor.name} (#${unit.unitNumber})`.trim();
				}
				return 'Unit N/A';
			} else {
				if (product && product.lot) {
					const { lot, unit } = product;
					const blockInfo = lot.block ? ` (Block ${lot.block})` : '';
					const address = lot.address ? `${lot.address} - ` : '';
					const unitGroup = unit && unit.unitGroup;
					const unitDetails = unitGroup ? `${unitGroup.name} ${unit.name} - ${unitGroup.size}' ${unitGroup.type}` : '';
					return `${address}Lot ${lot.name}${blockInfo} (${unitDetails})`.trim();
				}
				return 'Lot N/A';
			}
		},
		transactionName() {
			if (this.instance.productType == 'highrise') {
				let { product = {}, purchasers = [], type } = this.transaction
				let { floor = {} } = product
				let name = `${floor.name}`

				if (purchasers.length === 1) name += ` - (${type === 'purchase' ? 'Buyer' : 'Tenant'}: ${purchasers[0].fullName})`
				else name += ` - ${purchasers.length} ${type === 'purchase' ? 'Purchasers' : 'Tenants'}`

				return name.trim()
			} else {
				let { product = {}, purchasers = [], type } = this.transaction
				let { lot = {} } = product
				let name = `Lot ${lot.name}`
				if (lot.address) {
					name += ` - ${lot.address}`
				} else {
					if (purchasers.length === 1) name += ` - (${type === 'purchase' ? 'Buyer' : 'Tenant'}: ${purchasers[0].fullName})`
					else name += ` - ${purchasers.length} ${type === 'purchase' ? 'Purchasers' : 'Tenants'}`

				}
				return name.trim()
			}
		},
		reservationAmount() {
			return this.transaction && this.transaction.reservation && this.transaction.reservation.amount || 0;
		},

        passportOther() {
			return this.$store.state.appData.passportOther
		},

        showInventoryAddOns() {
            return Object.values(this.product?.unit?.package?.other?.addons || {}).some(a => a?.length)
        },

        showNonInventoryAddOns() {
            return Object.values(this.product?.unit?.package?.other?.nonInventoryAddOns || {}).some(a => a?.length)
        },
	},

	watch: {
		download(newValue) {
			if (newValue) {
				this.downloadPDF();
			}
		},
	},

	methods: {
		formatDate, formatNumber, moment,
		formatDateString(date){
			if (!date || typeof date !== 'string') return;

			const [year, month, day] = date.split('-');
			const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
			const shortMonth = months[parseInt(month, 10) - 1];

			return `${shortMonth}/${day}/${year}`;
		},
		async downloadPDF() {
			this.$nprogress.start();
			try {
				let promisesArray = [];
				for (let index = 0; index < this.purchasers.length; index++) {
					const purchaser = this.purchasers[index];
					promisesArray.push(this.fetchPurchaserDocs(purchaser))
				}
				await Promise.all(promisesArray)
			} catch (err) {
				console.error('Error while fetching purchaser documents')
			}
			// Put it in a setTimeout to make sure the PDF is rendered
			this.$nextTick(() => {
				this.$refs.html2Pdf.generatePdf();
			});
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			try {
				options.filename = `${this.transactionHeader}`;
				await html2pdf()
					.set(options)
					.from(pdfContent)
					.toPdf()
					.get("pdf")
					.save()
					.then(() => {
						this.$nprogress.done();
						this.$emit('completed')
					})
					.catch(() => {
						this.$nprogress.done();
					});
			} catch (error) {
				this.$nprogress.done();
				console.error('Error occurred while downloading the file', error)
			}

		},

		async fetchPurchaserDocs(purchaser) {
			this.purchaserImages = [];
			if (purchaser.fileFront && purchaser.fileFront.id && purchaser.fileBack && purchaser.fileBack.id) {
				let result = await Promise.all([this.fetchFileSignedUrl(purchaser.fileFront.id), this.fetchFileSignedUrl(purchaser.fileBack.id)]);
				if (result[0]) {
					this.purchaserImages.push({ name: 'License Front Photo', image: result[0] })
				}
				if (result[1]) {
					this.purchaserImages.push({ name: 'License Back Photo', image: result[1] })
				}
			}
			if (purchaser.filePassport && purchaser.filePassport.id) {
				const url = await this.fetchFileSignedUrl(purchaser.filePassport.id)
				if (url) {
					this.purchaserImages.push({ name: 'Passport Photo', image: url })
				}
			}
		},

		async fetchFileSignedUrl(id) {
			try {
				const { data } = await this.$api.post(`upload/files/get-signed-url/${id}`)
				return data;
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while fetching image url. Please try again.`))
				}
				return null
			}
		}
	},
}
</script>


<style lang="scss">
.pdf-page-break {
    page-break-before: always;
    break-before: page;
}
</style>
